import { gql } from 'apollo-boost';

export const REFUND_LIST_QUERY = gql`
  query GetRefundListByFilter(
    $filter: RefundPaymentFilterInput
    $page: Int
    $size: Int
  ) {
    payment {
      refunds(filter: $filter, page: $page, size: $size) {
        totalCount
        pageInfo {
          hasNextPage
        }
        edges {
          node {
            id
            customer {
              id
              nickname
              email
              mobileNumber
            }
            description
            note
            createdAt
            askedAt
            startedAt
            completedAt
            account {
              bank {
                code
                name
              }
              accountNo
              holder
              verified
            }
            amount {
              value
              currencyCode
            }
            status
            manager {
              id
              nickname
            }
            payment {
              id
              transaction {
                id
                order {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;
