
























import { apolloClient } from '@/apolloClient';
import { ContainerMixin } from '@/common/mixin/containerMixin';
import BankAccountInput from '../../order/components/BankAccountInput.vue';
import { RefundAccount } from '../../order/model/RefundAccount';
import { RefundPayment } from '../model/RefundPayment';
import { RefundService } from '../service/RefundService';

const service = new RefundService(apolloClient);

export default ContainerMixin.extend({
  components: { BankAccountInput },
  name: 'BankAccountEditPopup',
  data() {
    return {
      opened: false,
      param: {} as RefundPayment,
      account: {} as RefundAccount,
    };
  },
  methods: {
    async onClickOK(): Promise<void> {
      console.log('onClickOK');

      if (this.account.verified !== true) {
        return this.$modal.show({
          title: '등록 실패',
          message: '계좌 검증 확인이 되어야 등록 가능합니다.',
          type: 'warning',
        });
      }

      try {
        await service.updateAccountInfo(this.param.id, {
          code: this.account.code,
          accountNo: this.account.accountNo,
          holder: this.account.holder,
          verified: this.account.verified,
        });
        this.$modal.show(
          {
            title: '환불 계좌 등록 성공',
            message: '정상 처리되었습니다.',
            type: 'primary',
          },
          () => {
            this.opened = false;
          }
        );
        this.$emit('success', true);
      } catch (err) {
        this.showErrorAlert(err, '환불 계좌 등록 실패');
      }
    },
    open(param: RefundPayment): void {
      this.param = param;
      this.account = {
        code: param.account.bank?.code || '',
        accountNo: param.account.accountNo || '',
        holder: param.account.holder || '',
        verified: param.account.verified,
      };
      this.opened = true;
    },
  },
});
