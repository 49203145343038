export enum RefundState {
  REGISTERED = 'REGISTERED',
  ASKED = 'ASKED',
  COMPLETED = 'COMPLETED',
  FAILURE = 'FAILURE',
  PROGRESSING = 'PROGRESSING',
}

export const RefundStateLabels: {
  value: RefundState;
  label: string;
  color: string;
}[] = [
  {
    value: RefundState.REGISTERED,
    label: '환불 등록',
    color: 'primary',
  },
  {
    value: RefundState.ASKED,
    label: '환불 신청',
    color: 'warning',
  },
  {
    value: RefundState.PROGRESSING,
    label: '환불 중',
    color: 'info',
  },
  {
    value: RefundState.COMPLETED,
    label: '환불 완료',
    color: 'success',
  },
  {
    value: RefundState.FAILURE,
    label: '환불 실패',
    color: 'danger',
  },
];
