import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import {
  ASK_REFUND_PAYMENTS,
  COMPLETE_REFUND_PAYMENTS,
  PROCESS_REFUND_PAYMENTS,
  REPORT_REFUND_PAYMENT_FAILURE,
  UPDATE_REFUND_ACCOUNT,
  GENERATE_REFUND_FILE,
} from '../graphql/mutation';
import { RefundBankAccountParamInput } from '../model/RefundBankAccountParamInput';
import { RefundPaymentFilter } from '../model/RefundPaymentFilter';

export class RefundService {
  constructor(private readonly apollo: ApolloClient<NormalizedCacheObject>) {}

  public async updateAccountInfo(
    id: string,
    param: RefundBankAccountParamInput
  ): Promise<void> {
    const { data } = await this.apollo.mutate({
      mutation: UPDATE_REFUND_ACCOUNT,
      variables: {
        id: id,
        param: param,
      },
    });
    console.log('updateAccountInfo:', data.updateAccountInfo);
  }

  public async ask(ids: string[]): Promise<void> {
    const { data } = await this.apollo.mutate({
      mutation: ASK_REFUND_PAYMENTS,
      variables: {
        ids: ids,
      },
    });
    console.log('askRefunds:', data.askRefunds);
  }

  public async process(ids: string[]): Promise<void> {
    const { data } = await this.apollo.mutate({
      mutation: PROCESS_REFUND_PAYMENTS,
      variables: {
        ids: ids,
      },
    });
    console.log('processRefunds:', data.processRefunds);
  }

  public async complete(ids: string[]): Promise<void> {
    const { data } = await this.apollo.mutate({
      mutation: COMPLETE_REFUND_PAYMENTS,
      variables: {
        ids: ids,
      },
    });
    console.log('completeRefunds:', data.completeRefunds);
  }

  public async reportFailure(id: string, note: string): Promise<void> {
    const { data } = await this.apollo.mutate({
      mutation: REPORT_REFUND_PAYMENT_FAILURE,
      variables: {
        id: id,
        note: note,
      },
    });
    console.log('reportRefundFailure:', data.reportRefundFailure);
  }

  public async generateFile(filter: RefundPaymentFilter): Promise<FileResult> {
    const { data } = await this.apollo.mutate({
      mutation: GENERATE_REFUND_FILE,
      variables: {
        filter: filter,
      },
    });
    console.log('generateFile:', data);
    return data.generateRefundsFile;
  }
}

interface FileResult {
  success: boolean;
  message: string;
  url: string;
}
