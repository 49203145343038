




import Vue from 'vue';
import { RefundStateLabels } from '../../model/RefundState';

export default Vue.extend({
  name: 'RefundStatusLabel',
  props: {
    status: {
      type: String,
    },
  },
  computed: {
    label: function (): string {
      return (
        RefundStateLabels.find(i => i.value.toString() == this.status)?.label ||
        ''
      );
    },
    variant: function (): string {
      return (
        RefundStateLabels.find(i => i.value.toString() == this.status)?.color ||
        'secondary'
      );
    },
  },
});
