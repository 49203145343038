import { Dictionary } from 'vue-router/types/router';
import { Term } from '@/common/Term';
import { RefundState } from './RefundState';
import { toStringArray } from '@/common/util/toStringArray';

export interface RefundPaymentFilter {
  customerIds: string[] | null;
  statusIn: RefundState[] | null;
  termWithType: RefundPaymentTermWithType | null;
  descriptionLike: string | null;
}

export interface RefundPaymentFilterQuery
  extends Dictionary<string | string[] | undefined> {
  customerIds?: string[];
  statusIn?: string[];
  descriptionLike?: string;
  termType?: string;
  startedAt?: string;
  endedAt?: string;
}

export interface RefundPaymentTermWithType {
  type: RefundPaymentTermSearchType;
  term: Term | null;
}

export enum RefundPaymentTermSearchType {
  REGISTERED = 'REGISTERED',
  ASKED = 'ASKED',
  STARTED = 'STARTED',
  COMPLETED = 'COMPLETED',
}

export const toFilter = (
  query: RefundPaymentFilterQuery
): RefundPaymentFilter => {
  let term: Term | null = null;
  if (query.startedAt || query.endedAt) {
    term = {
      startedAt: query.startedAt ? Number(query.startedAt) : null,
      endedAt: query.endedAt ? Number(query.endedAt) : null,
    };
  }
  return {
    termWithType: {
      type:
        (query.termType as RefundPaymentTermSearchType) ||
        RefundPaymentTermSearchType.REGISTERED,
      term: term,
    },
    customerIds: toStringArray(query.customerIds) || null,
    statusIn: toStringArray(query.statusIn)?.map(i => i as RefundState) || null,
    descriptionLike: query.descriptionLike || null,
  };
};
