























































































































































import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Button,
} from 'element-ui';
import Spinner from '@/components/Spinner.vue';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { REFUND_LIST_QUERY } from '../graphql/query';
import MoneyLabel from '@/components/Labels/MoneyLabel.vue';
import { Term } from '@/common/Term';
import {
  RefundPaymentFilter,
  RefundPaymentFilterQuery,
  RefundPaymentTermSearchType,
  toFilter,
} from '../model/RefundPaymentFilter';
import { RefundState } from '../model/RefundState';
import { RefundPayment } from '../model/RefundPayment';
import RefundStatusLabel from './controls/RefundStatusLabel.vue';
import BankAccountEditPopup from './BankAccountEditPopup.vue';
import { ContainerMixin } from '@/common/mixin/containerMixin';
import { apolloClient } from '@/apolloClient';
import { RefundService } from '../service/RefundService';
import { toStringArray } from '@/common/util/toStringArray';

interface Connection<T> {
  totalCount: number;
  edges: Edge<T>[];
}

interface Edge<T> {
  node: T;
}

const service = new RefundService(apolloClient);

export default ContainerMixin.extend({
  name: 'RefundList',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Button.name]: Button,
    Spinner,
    RefundStatusLabel,
    MoneyLabel,
    BankAccountEditPopup,
  },
  data() {
    return {
      loading: 0,
      refunds: [] as RefundPayment[],
      selection: [] as RefundPayment[],
      totalCount: 0,
      size: 50,
    };
  },
  computed: {
    numberOfPages(): number {
      return Math.ceil(this.totalCount / this.size);
    },
    filter(): RefundPaymentFilter {
      const query = this.$route.query as RefundPaymentFilterQuery;
      return toFilter(query);
    },
  },
  methods: {
    handleSelectionChange(items: RefundPayment[]): void {
      console.log('handleSelectionChange:', items);
      this.selection = items;
    },
    getAccountInfo(row: RefundPayment): string {
      return `${row.account?.bank?.name || ''}  ${
        row.account?.accountNo || ''
      } ${row.account.holder || ''}`;
    },
    canAsking(): boolean {
      if (this.selection.length == 0) {
        return false;
      }
      return this.selection.every(i => i.status == RefundState.REGISTERED);
    },
    canStarting(): boolean {
      if (this.selection.length == 0) {
        return false;
      }
      return this.selection.every(i => i.status == RefundState.ASKED);
    },
    canComplating(): boolean {
      if (this.selection.length == 0) {
        return false;
      }
      return this.selection.every(i => i.status == RefundState.PROGRESSING);
    },
    async onClickAsking() {
      console.log('onClickAsking');
      if (this.selection.length == 0) {
        return this.$alert('선택된 항목이 없습니다.', '실패');
      }

      try {
        await service.ask(this.selection.map(i => i.id));
      } catch (e) {
        this.showErrorAlert(e, '환불 신청 실패');
      }
    },
    async onClickStarting() {
      console.log('onClickStarting');
      if (this.selection.length == 0) {
        return this.$alert('선택된 항목이 없습니다.', '실패');
      }

      try {
        await service.process(this.selection.map(i => i.id));
      } catch (e) {
        this.showErrorAlert(e, '환불 진행 실패');
      }
    },
    async onClickCompleting() {
      console.log('onClickCompleting');
      if (this.selection.length == 0) {
        return this.$alert('선택된 항목이 없습니다.', '실패');
      }

      try {
        await service.complete(this.selection.map(i => i.id));
      } catch (e) {
        this.showErrorAlert(e, '환불 완료 실패');
      }
    },
    onClickAccount(row: RefundPayment) {
      console.log('onClickCompleting');
      const popup = this.$refs.BankAccountEditPopup as InstanceType<
        typeof BankAccountEditPopup
      >;
      popup.open(row);
    },
    onSuccessBankAccountEdit(): void {
      console.log('onSuccessBankAccountEdit');
    },
    linkGen(pageNum: number) {
      return {
        query: {
          ...this.$route.query,
          page: pageNum > 1 ? pageNum : undefined,
        },
      };
    },
    toDateString(date: number | null): string {
      if (date) {
        return this.$moment(date).format('YYYY-MM-DD');
      }
      return '';
    },
    refresh() {
      this.$apollo.queries.refunds.refresh();
    },
  },
  apollo: {
    refunds: {
      query: REFUND_LIST_QUERY,
      variables(): { page: number; size: number; filter: RefundPaymentFilter } {
        return {
          page: Number(this.$route.query.page) || 1,
          size: this.size,
          filter: this.filter,
        };
      },
      error(e: ApolloError) {
        console.error(e);
      },
      // manual: true,
      result(
        result: ApolloQueryResult<{
          payment: { refunds: Connection<RefundPayment> };
        }>
      ) {
        this.totalCount = result.data.payment.refunds.totalCount;
      },
      update: (data: { payment: { refunds: Connection<RefundPayment> } }) =>
        data.payment.refunds.edges.map(edge => edge.node),
    },
  },
});
