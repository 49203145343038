


















































































import { ContainerMixin } from '@/common/mixin/containerMixin';
import { Select, Option } from 'element-ui';
import Icon from '@/components/Icon/Icon.vue';
import {
  RefundPaymentFilterQuery,
  RefundPaymentTermSearchType,
  toFilter,
} from '../model/RefundPaymentFilter';
import { Term } from '@/common/Term';
import FripButton from '@/components/FripComponents/FripButton.vue';
import VueRouter from 'vue-router';
import { RefundStateLabels } from '../model/RefundState';
import { toStringArray } from '@/common/util/toStringArray';
import { apolloClient } from '@/apolloClient';
import { RefundService } from '../service/RefundService';

const service = new RefundService(apolloClient);

export default ContainerMixin.extend({
  name: 'RefundSearchForm',
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    FripButton,
    Icon,
  },
  data() {
    const query = this.$route.query as RefundPaymentFilterQuery;
    let searchTarget = 'customerId';
    let searchKeyword = '';

    if (query.customerIds) {
      searchTarget = 'customerId';
      searchKeyword = toStringArray(query.customerIds)?.join(',') || '';
    } else if (query.descriptionLike) {
      searchTarget = 'description';
      searchKeyword = query.descriptionLike;
    }

    return {
      totalCount: 0,
      searchTarget: searchTarget,
      searchKeyword: searchKeyword,
      searchOptions: [
        { value: 'customerId', text: '고객ID' },
        { value: 'description', text: '환불 내용' },
      ],
      termTypes: [
        { value: RefundPaymentTermSearchType.REGISTERED, text: '환불 등록일' },
        { value: RefundPaymentTermSearchType.ASKED, text: '환불 요청일' },
        { value: RefundPaymentTermSearchType.STARTED, text: '환불 시작일' },
        {
          value: RefundPaymentTermSearchType.COMPLETED,
          text: '환불 완료일',
        },
      ],
      searchTermType: query.termType || RefundPaymentTermSearchType.REGISTERED,
      searchTerm: {
        startedAt: query.startedAt ? Number(query.startedAt) : null,
        endedAt: query.endedAt ? Number(query.endedAt) : null,
      } as Term,
      statusIn:
        toStringArray(query.statusIn) || RefundStateLabels.map(i => i.value),
      stateLabels: RefundStateLabels,
    };
  },
  computed: {
    searchKeywordPlaceholder(): string {
      if (this.searchTarget === 'customerId') {
        return '고객 아이디를 , 로 구분해 입력해주세요.';
      } else {
        return '검색어를 입력해주세요';
      }
    },
    allStatusChecked: {
      get(): boolean {
        return this.statusIn.length === RefundStateLabels.length;
      },
      set(newValue: boolean) {
        if (newValue) {
          this.statusIn = RefundStateLabels.map(i => i.value);
        } else if (this.statusIn.length === RefundStateLabels.length) {
          this.statusIn = [];
        }
      },
    },
  },
  methods: {
    clear() {
      this.searchTarget = 'customerId';
      this.searchKeyword = '';
      this.searchTermType = RefundPaymentTermSearchType.REGISTERED;
      this.searchTerm = { startedAt: null, endedAt: null };
      this.statusIn = RefundStateLabels.map(i => i.value);

      this.$router.push({ query: undefined }).catch();
    },
    onClickSearch() {
      const filter = this.buildFilter();
      this.$router.push({ query: filter }).catch(err => {
        if (
          VueRouter.isNavigationFailure(
            err,
            VueRouter.NavigationFailureType.duplicated
          )
        ) {
          this.$emit('refresh', true);
        }
      });
    },
    buildFilter(): RefundPaymentFilterQuery {
      const filter: RefundPaymentFilterQuery = {};

      if (this.searchKeyword) {
        if (this.searchTarget === 'customerId') {
          filter.customerIds = this.searchKeyword.split(',').map(i => i.trim());
        } else if (this.searchTarget === 'description') {
          filter.descriptionLike = this.searchKeyword;
        }
      }

      if (this.searchTerm.startedAt) {
        filter.startedAt = this.searchTerm.startedAt.toString();
      }

      if (this.searchTerm.endedAt) {
        filter.endedAt = this.searchTerm.endedAt.toString();
      }

      filter.termType = this.searchTermType;

      filter.statusIn = this.statusIn;

      return filter;
    },
    async downloadFile(): Promise<void> {
      const filter = this.buildFilter();
      try {
        const result = await service.generateFile(toFilter(filter));

        if (!result.success) {
          return this.$modal.show({
            title: '엑셀 다운로드 실패',
            message: result.message,
            type: 'warning',
          });
        }

        window.location.href = result.url;
      } catch (err) {
        this.showErrorAlert(err, '엑셀 다운로드 실패');
      }
    },
  },
});
