







import Vue from 'vue';
import RefundSearchForm from '../components/RefundSearchForm.vue';
import RefundList from '../components/RefundList.vue';

export default Vue.extend({
  name: 'RefundListContainer',
  components: {
    RefundSearchForm,
    RefundList,
  },
  methods: {
    onRefresh() {
      (this.$refs.list as any).refresh();
    },
  },
});
