import { gql } from 'apollo-boost';

export const UPDATE_REFUND_ACCOUNT = gql`
  mutation UpdateRefundAccount(
    $id: String!
    $param: RefundBankAccountParamInput!
  ) {
    updateRefundAccount(id: $id, param: $param) {
      id
      account {
        bank {
          code
          name
        }
        accountNo
        holder
        verified
      }
      note
      updatedAt
      status
      manager {
        id
        nickname
      }
    }
  }
`;

export const ASK_REFUND_PAYMENTS = gql`
  mutation AskRefundPayments($ids: [String!]!) {
    askRefunds(ids: $ids) {
      id
      note
      askedAt
      startedAt
      completedAt
      updatedAt
      status
      manager {
        id
        nickname
      }
    }
  }
`;

export const PROCESS_REFUND_PAYMENTS = gql`
  mutation ProcessRefundPayments($ids: [String!]!) {
    processRefunds(ids: $ids) {
      id
      note
      askedAt
      startedAt
      completedAt
      updatedAt
      status
      manager {
        id
        nickname
      }
    }
  }
`;

export const COMPLETE_REFUND_PAYMENTS = gql`
  mutation CompleteRefundPayments($ids: [String!]!) {
    completeRefunds(ids: $ids) {
      id
      note
      askedAt
      startedAt
      completedAt
      updatedAt
      status
      manager {
        id
        nickname
      }
    }
  }
`;

export const REPORT_REFUND_PAYMENT_FAILURE = gql`
  mutation ReportRefundPaymentFailure($id: String!, $note: String!) {
    reportRefundFailure(id: $id, note: $note) {
      id
      note
      askedAt
      startedAt
      completedAt
      updatedAt
      status
      manager {
        id
        nickname
      }
    }
  }
`;

export const GENERATE_REFUND_FILE = gql`
  mutation GenerateRefundFile($filter: RefundPaymentFilterInput!) {
    generateRefundsFile(filter: $filter) {
      success
      message
      url
    }
  }
`;
